import { Controller } from "@hotwired/stimulus";
import "jstree";

// Connects to data-controller="jstree"
export default class extends Controller {
  static values = {
    rootId: Number,
    unedited: Boolean,
  };

  connect() {
    let url = "/categories/trees.json";
    if (this.hasUneditedValue && this.uneditedValue) {
      url = "/categories/unedited_trees.json";
    }

    $(this.element)
      .jstree({
        core: {
          themes: {
            icons: false,
          },
          data: {
            url: url,
          },
        },
      })
      .on("loaded.jstree", () => {
        $(this.element).jstree("open_all");
        const selectedNodeId = localStorage.getItem("selectedNodeId");

        $('li[id="' + selectedNodeId + '"] > a').addClass(
          "jstree-node-selected"
        );
      })
      .on("select_node.jstree", (e, data) => {
        const selected = $(this.element).jstree("get_selected", true);
        localStorage.setItem("selectedNodeId", data.node.id);
        Turbo.visit(selected[0].original["url"]);
      });
  }
}
