import { Controller } from "@hotwired/stimulus";
import bootstrap from "bootstrap/dist/js/bootstrap";

// Connects to data-controller="charged-video"
export default class extends Controller {
  connect() {
    console.log(this.element);
  }

  redirect(e) {
    e.preventDefault();
    let payModal = new bootstrap.Modal(document.getElementById("payModal"), {});
    payModal.show();
    document.getElementById("yesButton").addEventListener("click", function () {
      window.open("https://www.kahbps.or.kr/mypage/member_stat.php", "_blank");
      payModal.hide();
    });
  }
}
