function switchTheme(selector) {
  // console.log('switchTheme called...')
  const switches = document.querySelectorAll(selector);
  // let colorTheme = 'system';
  let colorTheme = localStorage.getItem("colorTheme") || "light"; //commented to avoid security issue

  function changeState() {
    localStorage.setItem("colorTheme", colorTheme); //commented to avoid security issue
    document.documentElement.setAttribute("data-theme", colorTheme);

    if (document.documentElement.getAttribute("data-theme") === "dark") {
      document.querySelector(".navbar-brand img").src =
        "/logo_transparent_dark.png";
      document.querySelector(".navbar-brand img").style.backgroundColor =
        "transparent";
      if (document.querySelector(".logo img")) {
        document.querySelector(".logo img").src = "/logo_transparent_dark.png";
        document.querySelector(".logo").style.color = "white";
        document.querySelector(".logo").style.backgroundColor = "black";
        document.querySelector(".logo p").style.color = "white";
      }
    } else {
      document.querySelector(".navbar-brand img").src = "/logo_transparent.png";
      document.querySelector(".navbar-brand img").style.backgroundColor =
        "transparent";
      if (document.querySelector(".logo img")) {
        document.querySelector(".logo").style.backgroundColor = "white";
        document.querySelector(".logo p").style.color = "gray";
        document.querySelector(".logo img").src = "/logo_transparent.png";
      }
    }
  }

  changeState();

  switches.forEach((el) => {
    el.addEventListener("click", () => {
      switch (colorTheme) {
        case "dark":
          colorTheme = "light";
          break;
        case "light":
          colorTheme = "dark"; // 'dark'
          break;
        default:
          colorTheme = "dark";
      }
      changeState();
    });
  });
}

export { switchTheme };
