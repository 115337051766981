import { Controller } from "@hotwired/stimulus";
import { Turbo } from "@hotwired/turbo-rails";

// Connects to data-controller="categories"
export default class extends Controller {
  static values = {
    pathids: String,
    label1: String,
    label2: String,
    label3: String,
    label4: String,
  };
  connect() {
    Turbo.clearCache();

    const path_ids = this.pathidsValue.split(",");
    // console.log("this.pathidsValue : ", this.pathidsValue);
    // console.log("path_ids : ", path_ids);
    // console.log("path_ids.last : ", path_ids.slice(-1)[0]);

    const leaf_id = path_ids.slice(-1)[0];

    // $("#lecture_new_category").hide();

    this.resetCategories(path_ids);

    fetch(`/admin/categories/lookup_list.json`)
      .then((response) => response.json())
      .then((categories1) => {
        categories1.forEach((root) => {
          // console.log(root.id, path_ids);
          $("#category_1").append(
            `<option value="${root.id}" ${
              root.id === parseInt(path_ids[0]) ? "selected" : ""
            }>${root.name}</option>`
          );
        });
        return path_ids[0];
      })
      .then((data) => {
        if (data) {
          fetch(`/admin/categories/lookup_list.json?id=${data}`)
            .then((response) => response.json())
            .then((categories2) => {
              categories2.forEach((category2) => {
                // console.log(root.id, path_ids);
                $("#category_2").append(
                  `<option value="${category2.id}" ${
                    category2.id === parseInt(path_ids[1]) ? "selected" : ""
                  }>${category2.name}</option>`
                );
              });
              return path_ids[1];
            })
            .then((data) => {
              if (data) {
                fetch(`/admin/categories/lookup_list.json?id=${data}`)
                  .then((response) => response.json())
                  .then((categories3) => {
                    categories3.forEach((category3) => {
                      // console.log(root.id, path_ids);
                      $("#category_3").append(
                        `<option value="${category3.id}" ${
                          category3.id === parseInt(path_ids[2])
                            ? "selected"
                            : ""
                        }>${category3.name}</option>`
                      );
                    });
                    return path_ids[2];
                  })
                  .then((data) => {
                    if (data) {
                      fetch(`/admin/categories/lookup_list.json?id=${data}`)
                        .then((response) => response.json())
                        .then((categories4) => {
                          categories4.forEach((category4) => {
                            // console.log(root.id, path_ids);
                            $("#category_4").append(
                              `<option value="${category4.id}" ${
                                category4.id === parseInt(path_ids[3])
                                  ? "selected"
                                  : ""
                              }>${category4.name}</option>`
                            );
                          });
                          // return path_ids[3];
                        })
                        .catch((error) => {
                          console.log(
                            "There has been a problem with your fetch operation:",
                            error
                          );
                        });
                    }
                  })
                  .catch((error) => {
                    console.log(
                      "There has been a problem with your fetch operation:",
                      error
                    );
                  });
              }
            })
            .catch((error) => {
              console.log(
                "There has been a problem with your fetch operation:",
                error
              );
            });
        }
      });
    if (leaf_id != "") {
      fetch(`/admin/categories/${path_ids.slice(-1)[0]}.json`)
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          $("#code")[0].innerText = data.code;
        });
    }
  }

  resetCategories(path_ids) {
    $("#category_1").empty();
    $("#category_1").append(
      `<option value="" ${parseInt(path_ids[0]) == "" ? "selected" : ""}>${
        this.label1Value
      }</option>`
    );
    $("#category_2").empty();
    $("#category_2").append(
      `<option value="" ${parseInt(path_ids[1]) == "" ? "selected" : ""}>${
        this.label2Value
      }</option>`
    );
    $("#category_3").empty();
    $("#category_3").append(
      `<option value="" ${parseInt(path_ids[2]) == "" ? "selected" : ""}>${
        this.label3Value
      }</option>`
    );
    $("#category_4").empty();
    $("#category_4").append(
      `<option value="" ${parseInt(path_ids[3]) == "" ? "selected" : ""}>${
        this.label4Value
      }</option>`
    );
  }
}
