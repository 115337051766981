import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="jstree-root"
export default class extends Controller {
  static values = {
    pathIds: Array,
    id: Number,
  };
  static targets = ["card"];
  connect() {
    // console.log("Hello, Stimulus!", this.element);
    // console.log(this.idsValue);
    const selectedNodeId = localStorage.getItem("selectedNodeId");
    // console.log(selectedNodeId);
    if (this.pathIdsValue.includes(parseInt(selectedNodeId))) {
      this.cardTarget.classList.add("border-primary");
      this.cardTarget.style.borderColor = "red";
    }
  }

  handleClick(event) {
    event.preventDefault();
    // console.log(this.idValue);
    localStorage.setItem("selectedNodeId", this.idValue);
    // console.log(event.currentTarget.href);
    Turbo.visit(event.currentTarget.href);
  }
}
