import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="attach-from-url"
export default class extends Controller {
  static targets = ["video_url"];
  connect() {
    console.log("Hello, Stimulus!", this.element);
    console.log(this.video_urlTarget.value);
  }
}
